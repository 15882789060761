import { Box, Container, Flex, Grid, Text, Image } from "@theme-ui/components"
import React from "react"
import { useCompany } from "../hooks/useCompany"
import { MagicLink } from "../utils/magicLink"
import { useSocial } from "../hooks/useSocial"
import { i18nContext } from "../context/i18nContext"
import {
  Facebook,
  Instagram,
  Twitter,
  Youtube,
  Linkedin,
  MapPin,
  Phone,
  Mail,
} from "react-feather"
import FooterLogo from "../images/logo-black.png"
import * as Scroll from "react-scroll"
import { OutboundLink } from "./link"

const LinkScroll = Scroll.Link

const Footer = ({ pageSections }) => {
  const social = useSocial()

  return (
    <Box as="footer" sx={{ backgroundColor: "primary", py: 5, color: "light" }}>
      <i18nContext.Consumer>
        {t => (
          <>
            <Container>
              <Grid columns={[1, 2, "1.2fr 1fr 1.7fr .7fr"]} gap={[0, 64]}>
                <Box sx={{ pb: [4] }}>
                  <Image variant="avatar" src={FooterLogo} alt="Logo" />
                </Box>
                <List>
                  <Item>
                    <Text sx={{ fontWeight: "bold", mb: 2, display: "block" }}>
                      Menu
                    </Text>
                    <List>
                      {pageSections.map(item => (
                        <Item
                          sx={{
                            position: "relative",
                            paddingY: 3,
                            color: "light",
                            fontSize: [0],
                            letterSpacing: ".2em",
                            lineHeight: "12px",
                            textTransform: "capitalize",
                            fontWeight: "400",
                          }}
                        >
                          {console.log("item", item)}
                          <LinkScroll
                            spy={false}
                            smooth={true}
                            duration={500}
                            to={`${item.id}`}
                            href="#"
                          >
                            {item.title}
                          </LinkScroll>
                        </Item>
                      ))}
                    </List>
                  </Item>
                </List>
                <List>
                  <Item>
                    <Text sx={{ fontWeight: "bold", mb: 2, display: "block" }}>
                      Contatti
                    </Text>
                    <List>
                      <Item
                        sx={{
                          position: "relative",
                          paddingY: 3,
                          color: "light",
                          fontSize: [0],
                          letterSpacing: ".2em",
                          lineHeight: "12px",
                          fontWeight: "400",
                          display: "flex",
                          alignItems: "center",
                        }}
                      >
                        <Box sx={{ minWidth: "24px", mr: [3] }}>
                          <MapPin size={24} />
                        </Box>
                        <Box>
                          <Text>Via Campo Romano, 13 24050 Spirano (BG)</Text>
                        </Box>
                      </Item>
                      <Item
                        sx={{
                          position: "relative",
                          paddingY: 3,
                          color: "light",
                          fontSize: [0],
                          letterSpacing: ".2em",
                          lineHeight: "12px",
                          fontWeight: "400",
                          display: "flex",
                          alignItems: "center",
                        }}
                      >
                        <Box sx={{ minWidth: "24px", mr: [3] }}>
                          <Phone size="24" />
                        </Box>
                        <OutboundLink
                          sx={{
                            color: "light",
                            textDecoration: "underline",
                          }}
                          href="tel:+035320144"
                        >
                          +035 320144
                        </OutboundLink>
                      </Item>
                      <Item
                        sx={{
                          position: "relative",
                          paddingY: 3,
                          color: "light",
                          fontSize: [0],
                          letterSpacing: ".2em",
                          lineHeight: "12px",
                          fontWeight: "400",
                          display: "flex",
                          alignItems: "center",
                        }}
                      >
                        <Box sx={{ minWidth: "24px", mr: [3] }}>
                          <Mail size="24" />
                        </Box>
                        <OutboundLink
                          sx={{
                            color: "light",
                            textDecoration: "underline",
                          }}
                          href="mailto:automation@mecmatica-automation.it"
                        >
                          automation@mecmatica-automation.it
                        </OutboundLink>
                      </Item>
                    </List>
                  </Item>
                </List>
                <List>
                  <Item>
                    <Text sx={{ fontWeight: "bold", mb: 2, display: "block" }}>
                      Social Media
                    </Text>
                    <List>
                      <Item
                        sx={{
                          display: "flex",
                          position: "relative",
                          paddingY: 3,
                          color: "light",
                          fontSize: [0],
                          letterSpacing: ".2em",
                          lineHeight: "12px",
                          textTransform: "capitalize",
                          fontWeight: "400",
                        }}
                      >
                        <Grid columns={[4]} gap={[3]} sx={{ mt: [2, 0] }}>
                          {social.facebook && (
                            <Box>
                              <MagicLink target="_blank" href={social.facebook}>
                                <Facebook color="#FFF" />
                              </MagicLink>
                            </Box>
                          )}
                          {social.instagram && (
                            <Box>
                              <MagicLink
                                target="_blank"
                                href={social.instagram}
                              >
                                <Instagram color="#FFF" />
                              </MagicLink>
                            </Box>
                          )}
                          {social.linkedin && (
                            <Box>
                              <MagicLink target="_blank" href={social.linkedin}>
                                <Linkedin color="#FFF" />
                              </MagicLink>
                            </Box>
                          )}
                          {social.youtube && (
                            <Box>
                              <MagicLink target="_blank" href={social.youtube}>
                                <Youtube color="#FFF" />
                              </MagicLink>
                            </Box>
                          )}
                        </Grid>
                      </Item>
                    </List>
                  </Item>
                </List>
              </Grid>
              <Box>
                <Box as="p" sx={{fontSize:[0]}}>
                  P.IVA &amp; R.I. n° 02792500163 - Cap. Soc. € 50.000,00 i.v. -{" "}
                  <OutboundLink
                    sx={{
                      color: "light",
                      textDecoration: "underline",
                    }}
                    href="http://www.mecmatica.it/privacy/"
                    target="_blank"
                  >
                    Privacy 
                  </OutboundLink>
                  {" "}
                  <OutboundLink
                    sx={{
                      color: "light",
                      textDecoration: "underline",
                    }}
                    href="http://www.mecmatica.it/cookie-policy/"
                    target="_blank"
                  >
                    Cookie policy
                  </OutboundLink>
                </Box>
              </Box>
            </Container>
          </>
        )}
      </i18nContext.Consumer>
    </Box>
  )
}

const List = props => {
  return (
    <Flex
      {...props}
      sx={{
        flexDirection: "column",
        margin: 0,
        padding: 0,
        mb: 4,
        listStyle: "none",
        a: {
          textDecoration: "none",
          "&:hover": {
            textDecoration: "underline",
          },
        },
      }}
      as="ul"
    />
  )
}

const Item = props => {
  return <Box {...props} as="li" />
}

export default Footer
